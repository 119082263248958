<template>
  <DefaultTemplate
    :footer="true"
    v-loading.lock="loading"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
    id="change-password"
  >
    <el-row :gutter="15" type="flex" justify="center">
      <el-col :span="24" :offset="0">
        <el-row>
          <el-col
            :span="24"
            :xs="{ span: 24, offset: 0 }"
            :sm="{ span: 24, offset: 0 }"
            :md="{ span: 18, offset: 3 }"
            :lg="{ span: 18, offset: 3 }"
            :xl="{ span: 12, offset: 6 }"
          >
            <el-row :gutter="15">
              <el-col :span="24">
                <el-form
                  label-position="top"
                  :model="form"
                  ref="ruleForm"
                  :rules="rules"
                >
                  <h1 class="text-center color-main font-weight-normal">
                    Change Password
                  </h1>
                  <el-form-item label="รหัสผ่านปัจจุบัน" prop="oldPassword">
                    <el-input
                      v-model="form.oldPassword"
                      show-password
                      placeholder="กรุณากรอกรหัสผ่านปัจจุบัน"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item label="รหัสผ่านใหม่" prop="password">
                    <el-input
                      v-model="form.password"
                      show-password
                      placeholder="กรุณากรอกรหัสผ่านใหม่"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item label="ยืนยันรหัสผ่านใหม่" prop="repassword">
                    <el-input
                      v-model="form.repassword"
                      show-password
                      placeholder="กรุณากรอกรหัสผ่านใหม่"
                    >
                    </el-input>
                  </el-form-item>
                  <div class="flex-between mg-t-5">
                    <el-button round @click="$router.go(-1)">Cancel</el-button>
                    <el-button
                      type="warning"
                      round
                      :loading="loadingBtn"
                      @click="submitChangePass()"
                      >Save</el-button
                    >
                  </div>
                </el-form>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
  </DefaultTemplate>
</template>

<script>
import "@/mixin/DefaultTemplate";
import { HTTP } from "@/service/axios";
import { mapState } from "vuex";

export default {
  components: {},
  computed: {
    ...mapState({
      _token: (state) => state._token,
    }),
  },
  mounted() {},
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("กรุณากรอกรหัสผ่าน"));
      } else {
        if (this.form.repassword !== "") {
          this.$refs.ruleForm.validateField("confirmPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("กรุณากรอกรหัสผ่านอีกครั้ง"));
      } else if (value !== this.form.password) {
        callback(new Error("รหัสผ่านไม่ตรงกัน"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      loadingBtn: false,
      form: {
        oldPassword: "",
        password: "",
        repassword: "",
      },
      rules: {
        oldPassword: [
          {
            required: true,
            message: "กรุณากรอกรหัสผ่านปัจจุบัน",
            trigger: ["blur", "change"],
          },
          { validator: validatePass, trigger: ["blur", "change"] },
        ],
        password: [
          {
            required: true,
            message: "กรุณากรอกรหัสผ่านใหม่",
            trigger: ["blur", "change"],
          },
          { validator: validatePass, trigger: ["blur", "change"] },
          {
            min: 8,
            message: "รหัสผ่านต้องมีความยาวไม่น้อยกว่า 8 ตัวอักษร",
            trigger: ["blur", "change"],
          },
        ],
        repassword: [
          {
            required: true,
            message: "กรุณากรอกยืนยันรหัสผ่านใหม่",
            trigger: ["blur", "change"],
          },
          { validator: validatePass2, trigger: ["blur", "change"] },
          {
            min: 8,
            message: "รหัสผ่านต้องมีความยาวไม่น้อยกว่า 8 ตัวอักษร",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    submitChangePass() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.loadingBtn = true;
          let obj = {
            password: this.form.oldPassword,
            newPassword: this.form.password,
          };

          HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
          HTTP.put(`user/change/password`, obj)
            .then((res) => {
              if (res.data.success) {
                this.$notify({
                  title: "แก้ไขรหัสผ่านสำเร็จแล้ว!",
                  type: "success",
                  customClass: "success",
                });
                this.signOut();
              } else {
                this.$message({
                  message: "รหัสผ่านปัจจุบันไม่ถูกต้อง",
                  type: "error",
                  duration: 4000,
                });
              }
            })
            .catch((e) => {
              if (e.response.data.status == 422) {
                this.$message({
                  message: "รหัสผ่านปัจจุบันไม่ถูกต้อง",
                  type: "error",
                  duration: 4000,
                });
              } else if (e.response.data.status == 401) {
                this.checkAuth();
              } else {
                this.alertCatchError(e);
              }
            })
            .finally(() => {
              this.loadingBtn = false;
            });
        }
      });
    },
    signOut() {
      window.localStorage.removeItem("UIC");
      document.cookie = "auth=;";
      delete HTTP.defaults.headers.common.Authorization;
      delete HTTP.defaults.headers.common['X-CSRF-TOKEN'];
      setTimeout(() => {
        this.$router.push(`/login`);
      }, 250);
    },
  },
};
</script>
